import React from "react";
import styled from "styled-components";

export const MonkeyContainer = styled.div`
  position: fixed;
  bottom: 8px;
  right: 8px;
  display: inline-block;
`;

export function Monkey() {
  return (
    <img width="28" height="31" src={require("../../public/monkey.svg")} />
  );
}
