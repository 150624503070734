import React from "react";
import { Post, PostType } from "../pages/blog/BlogPosts";
import { Link } from "./Components";

/**
 * Given a blog post, renders a link to it.
 */
export function BlogPostLink({ post }: { post: Post }) {
  const isHosted = post.attributes.postType === PostType.HOSTED;
  const link = isHosted
    ? "/blog" + post.attributes.permalink
    : post.attributes.permalink;

  return (
    <Link
      href={link}
      target={isHosted ? undefined : "_blank"}
      rel={isHosted ? undefined : "noreferrer noopener"}
    >
      {post.attributes.title}
    </Link>
  );
}
