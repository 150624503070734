export const logoColors = {
  [0]: "#f0b456", // orange
  [1]: "#d4a0c4", // pink
  [2]: "#4eace8", // blue
  [3]: "#534898", // purple
  orange: "#f0b456",
  pink: "#d4a0c4",
  blue: "#4eace8",
  purple: "#534898",
};
