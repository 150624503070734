import React from "react";
import styled, { css } from "styled-components";

export const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: sticky;
  top: 0;
  left: 0;
  padding-right: 32px;
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 20px;

  @media only screen and (max-device-width: 1000px) {
    background-color: white;
    align-items: center;
    padding-left: 16px;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const AlignHorizontally = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Bold = styled.span`
  font-family: "Neue Haas Unica W01 Regular";
  font-weight: bold;
`;

export const VerySmall = styled.span`
  font-size: 80%;
`;

export const Link = styled.a``;
export const DisabledLink = styled.span``;

export const Spacer = styled.div`
  ${({ width, height }: { width?: number; height?: number }) => css`
    width: 1em;
    height: 1em;
    ${width && !height ? "display: inline-block;" : ""}
    ${width ? `width: ${width}em;` : ""}
    ${height ? `height: ${height}em;min-height:${height}em;` : ""}
  `}
`;

export function Logo({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) {
  return (
    <LogoContainer
      style={{
        width: width ?? "84",
        height: height ?? "82",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
        }}
        src={require("../../public/logo.svg")}
      />
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  display: inline-block;
  @media only screen and (max-device-width: 1000px) {
    img {
      width: calc(84px * 1.4);
      height: calc(82px * 1.4);
    }
  }
`;

export function LogoWithText() {
  return (
    <LogoWithTextContainer>
      <img
        width="84"
        height="104"
        src={require("../../public/logo_with_text.svg")}
      />
    </LogoWithTextContainer>
  );
}

const LogoWithTextContainer = styled.div`
  display: inline-block;
  @media only screen and (max-device-width: 1000px) {
    img {
      width: calc(84px * 2.5);
      height: calc(104px * 2.5);
    }
  }
`;

export const BoxTitle = styled.div`
  display: block;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  width: 100%;
  margin-bottom: 8px;
`;
