import { motion } from "framer-motion";
import React from "react";
import { Spacer } from "./Components";
import { logoColors } from "./Constants";

function Letter({ letter, delay }: { letter: string; delay: number }) {
  let letterContents: React.ReactNode = letter;

  if (letter === " ") {
    letterContents = <Spacer width={0.25} />;
  }
  return (
    <motion.div
      style={{
        display: "inline-block",
        fontWeight: "bold",
      }}
      initial={{
        transform: `translateY(0px)`,
        color: "black",
      }}
      animate={{
        transform: `translateY(-3px)`,
        color: logoColors.orange,
      }}
      transition={{
        delay,
        duration: 0.5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    >
      {letterContents}
    </motion.div>
  );
}

export function ActiveLink({ text }: { text: string }) {
  return (
    <span>
      {text.split("").map((letter, i) => (
        <Letter letter={letter} key={i + letter} delay={i * 0.05} />
      ))}
    </span>
  );
}
