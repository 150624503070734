import React from "react";
import { Redirect } from "react-router";
import { Center, Link, Spacer } from "../../components/Components";

/**
 * Content rendered when there's no page that matches the URL which the user has navigated to.
 */
export function FourOhFour({
  home,
  redirect,
}: {
  home?: string;
  redirect?: boolean;
}) {
  const homeUrl = home ?? "/";
  return (
    <>
      <Spacer height={6} />
      <Center style={{ fontSize: "10em", color: "#aaa" }}>404</Center>
      <Center>
        {redirect ? (
          <Redirect to={homeUrl} />
        ) : (
          <Link href={homeUrl}>home</Link>
        )}
      </Center>
    </>
  );
}
