import { createGlobalStyle } from "styled-components";
import { logoColors } from "./Constants";

export const GlobalStyle = createGlobalStyle`
/*
  This CSS resource incorporates links to font software which is the valuable copyrighted
  property of Monotype and/or its suppliers. You may not attempt to copy, install,
  redistribute, convert, modify or reverse engineer this font software. Please contact
  Monotype with any questions regarding Web Fonts:  http://www.fontshop.com
*/

html {
}

body {
  font-family: "Neue Haas Unica W01 Light";
  font-size: 1em;
  margin: 0;
}

a {
  color: ${logoColors.blue};
  font-weight: bold;
  cursor: pointer;
  transition: color 50ms;
  text-decoration: underline;

  &:hover {
    color: ${logoColors.orange};
  }
}

ul {
  list-style: circle;
}

h1, h2, h3, strong, b {
  font-family: "Neue Haas Unica W01 Regular";
}

p code {
  background-color: #fafafa;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid #888888;
}

li code {
  background-color: #fafafa;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid #888888;
}

table, th, td {
  border: 1px solid #aaa;
  border-collapse: collapse;
}

table {
  width: 100%;
}

td, th {
  padding: 4px 8px;
}

pre {
  border-radius: 4px;
  border: 1px solid #888888;
  background-color: #fafafa;
  padding: 8px;
  overflow-y: none;
  overflow-x: auto;
}

hr {
  background-color: #ddd;
  border-color: #ddd;
  color: #ddd;
  border: none;
  height: 2px;
  border-radius: 2px;
}

.twitter-tweet {
  width: 100%;
}

@media only screen and (max-device-width: 1000px) {
  body {
    font-size: 2em;
  }
}

.not-mobile {
  display: none;
}

@media only screen and (max-device-width: 1000px) {
  .not-mobile {
    display: flex !important;
  }
}
`;
