import * as fs from "fs";
import * as path from "path";
const fm = require("front-matter");

export interface PostAttributes {
  date: string;
  permalink: string;
  title: string;
  postType: PostType;
  hidden?: boolean;
  app?: string;
}

export enum PostType {
  HOSTED,
  EXTERNAL,
}

export interface Post {
  attributes: PostAttributes;
  body: string;
}

const postContents = [
  fs
    .readFileSync(
      path.join(__dirname, "../../posts/Programmable_Cryptography_Part_1.md")
    )
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/ZK_Hunt.md")).toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/AW_Network.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Index_Supply_Announce.md"))
    .toString(),
  fs
    .readFileSync(
      path.join(__dirname, "../../posts/Autonomous_Worlds_Hackathon.md")
    )
    .toString(),
  fs
    .readFileSync(
      path.join(__dirname, "../../posts/2023_Vietnam_Residency_Announce.md")
    )
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/PARC_Squad.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Groth16_Recursion.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Succinct_Labs_Announce.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Personae_Labs_Announce.md"))
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/ETHdos.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Batch_ECDSA.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_Pairing_Part_2.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/AW_Residency.md"))
    .toString(),
  fs
    .readFileSync(
      path.join(__dirname, "../../posts/Autonomous_Worlds_Part_1.md")
    )
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Nightmarket.md"))
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/Procgen.md")).toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/zkREPL.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Halo2_Learning_Group.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_Pairing_Part_1.md"))
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/Ecne.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_ECDSA_Part_2.md"))
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/ZK_MNIST.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_ID_Part_2.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Summer_Residency.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Gitcoin_GR13_ZK.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Sequoia_NFT.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_Learning_Group_2.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Dark_Forest_Diamond.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_Airdrops.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_ECDSA_Part_1.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_ID_Part_1.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_Group_Signatures.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/ZK_Learning_Group.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Turing_Complete_Gov.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Six_ZK_Moonshots.md"))
    .toString(),
  fs.readFileSync(path.join(__dirname, "../../posts/Why_0xPARC.md")).toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/0xPARC_Announce.md"))
    .toString(),
  fs
    .readFileSync(path.join(__dirname, "../../posts/Dark_Forest_Announce.md"))
    .toString(),
];

export const posts = postContents.map((post) => fm(post) as Post);

export function getBlogPostFromUrl(path: string): Post | undefined {
  return posts.find((p) =>
    new RegExp(`^/blog${p.attributes.permalink}$`).test(path)
  );
}
