import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";

export function AnimatedLogo({
  delay,
  depth,
  initialScale,
}: {
  delay?: number;
  depth?: number;
  initialScale?: number;
}) {
  depth = depth ?? 1;

  if (depth === 0) {
    return null;
  }

  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [svgScale, setSvgScale] = useState(initialScale ?? 1);

  const logoAnimation = {
    initial: {
      opacity: 0,
      transform: "rotate(20deg) scale(0.8)",
    },
    animate: {
      transform: "rotate(0deg) scale(1)",
      opacity: 1,
    },
  };

  const logoTransition = {
    type: "spring",
    damping: 9.9,
    restSpeed: 0.001,
  };

  const pathAnimation = {
    initial: {
      pathLength: 1,
      strokeWidth: 2,
    },
    animate: {
      pathLength: 1,
      strokeWidth: 2,
    },
  };

  const pathTransition = logoTransition;

  const starsels = useMemo(() => {
    let starsels = [];
    let stars = 1 + depth;
    let scale = 0.45;
    let dist = 150;

    for (let i = 0; i < stars; i++) {
      const x = Math.cos((Math.PI * 2 * i) / stars) * dist * Math.sqrt(depth);
      const y = Math.sin((Math.PI * 2 * i) / stars) * dist * Math.sqrt(depth);

      starsels.push(
        <div
          key={i}
          style={{
            position: "absolute",
            transform: `scale(${scale}) translate(${x}px, ${y}px)`,
          }}
        >
          <AnimatedLogo delay={0.1 * i} depth={depth - 1} />
        </div>
      );
    }

    return starsels;
  }, []);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      const rect = svgRef?.getBoundingClientRect();
      if (!rect) return;

      const dist = Math.sqrt(
        Math.pow(rect.x - e.clientX, 2) + Math.pow(rect.y - e.clientY, 2)
      );
      const closeDist = 0;
      const farDist = 500;
      const maxScale = 1.1;

      if (dist > closeDist && dist < farDist) {
        setSvgScale(2);
        // setSvgScale((maxScale * (dist - closeDist)) / (farDist - closeDist));
      } else {
        setSvgScale(1);
      }
    };
    document.body.addEventListener("mousemove", onMouseMove);
    return () => document.body.removeEventListener("mousemove", onMouseMove);
  }, []);

  return (
    <motion.div
      style={{
        position: "relative",
      }}
      initial={logoAnimation.initial}
      animate={logoAnimation.animate}
      transition={Object.assign({}, logoTransition, { delay })}
    >
      {starsels}
      <motion.svg
        width="84"
        height="82"
        viewBox="0 0 84 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={setSvgRef}
        style={{
          transform: `scale(${svgScale})`,
        }}
      >
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M42.1461 81.1934L23.3965 23.011"
          stroke="#DE9EC6"
          strokeWidth="2"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M70.5055 69.6259L42.1455 15.2549"
          stroke="#56479D"
          strokeWidth="2"
          stroke-linecap="round"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M82.4503 41.4222L60.7104 60.0114"
          stroke="#E7B0D1"
          strokeWidth="2"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M70.6458 12.957L42.1455 67.5839"
          stroke="#00AEEF"
          strokeWidth="2"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M42.1459 1.25037L23.4917 59.9223"
          stroke="#FBB040"
          strokeWidth="2"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M13.5054 13.0961L60.8001 22.9165"
          stroke="#00AEEF"
          strokeWidth="2"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M1.8418 41.4222L15.7609 41.4444"
          stroke="#56479D"
          strokeWidth="2"
          stroke-linecap="round"
        />
        <motion.path
          initial={pathAnimation.initial}
          animate={pathAnimation.animate}
          transition={pathTransition}
          d="M13.6455 69.4867L68.531 41.5501"
          stroke="#FBB040"
          strokeWidth="2"
        />
      </motion.svg>
    </motion.div>
  );
}
