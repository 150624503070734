import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "../../components/GlobalStyle";
import { FourOhFour } from "../../pages/404/404";
import { AboutPage } from "../../pages/about/AboutPage";
import { BlogPage } from "../../pages/blog/BlogPage";
import { LandingPage } from "../../pages/landing/LandingPage";
// @ts-ignore
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you

/**
 * This is the root of the application.
 */
export function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path="/about" exact component={AboutPage} />
          <Route path="/blog" exact component={BlogPage} />
          <Route path="/blog/:permalink" exact component={BlogPage} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
