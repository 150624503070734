import React from "react";
import { BlogPostLink } from "../../components/BlogpostLink";
import { Spacer, VerySmall } from "../../components/Components";
import { Markdown } from "../../components/Markdown";
import { SubPage } from "../../components/SubPage";
import { FourOhFour } from "../404/404";
import { getBlogPostFromUrl, Post, posts, PostType } from "./BlogPosts";

/**
 * A blog entry on the blog page
 */
function BlogEntry({ post }: { post: Post }) {
  const isHosted = post.attributes.postType === PostType.HOSTED;
  const link = isHosted
    ? "/blog" + post.attributes.permalink
    : post.attributes.permalink;

  return (
    <div>
      <VerySmall>{post.attributes.date}</VerySmall>
      <br />
      <BlogPostLink post={post} />
      <Spacer height={1} />
    </div>
  );
}

export function BlogPage() {
  const blogPost = getBlogPostFromUrl(window.location.pathname);

  if (
    window.location.pathname === "/blog" ||
    window.location.pathname === "/blog/"
  ) {
    return (
      <SubPage>
        {posts
          .filter((p) => !p.attributes.hidden)
          .map((p, i) => (
            <BlogEntry key={i} post={p} />
          ))}
        <Spacer height={8} />
      </SubPage>
    );
  }

  if (blogPost === undefined) {
    return <FourOhFour />;
  }

  return (
    <SubPage>
      <Markdown>{blogPost.body}</Markdown>
      <Spacer height={8} />
    </SubPage>
  );
}
