import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import {
  ElementContent,
  TransformLinkTargetType,
} from "react-markdown/lib/ast-to-react";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
const headingId = require("remark-heading-id");
const fm = require("front-matter");
const wrap = require("rehype-wrap-all");

export function Markdown({
  children,
  reactiveTable,
}: {
  children: string;
  reactiveTable?: boolean;
}) {
  const frontMatter = useMemo(() => {
    return fm(children);
  }, [children]);

  const rehypePlugins = useMemo(() => {
    const plugins: any[] = [rehypeRaw, rehypeKatex];
    if (!reactiveTable) {
      plugins.push([
        wrap,
        { selector: "table", wrapper: "div.responsive-table" },
      ]);
    }
    return plugins;
  }, [reactiveTable]);

  return (
    <ReactMarkdown
      linkTarget={(
        href: string,
        children: Array<ElementContent>,
        title: string | null
      ): TransformLinkTargetType | undefined => {
        try {
          const currentUrl = new URL(window.location.href);
          const targetUrl = new URL(href);
          if (currentUrl.origin !== targetUrl.origin) {
            return "_blank";
          }
        } catch {}

        return undefined;
      }}
      rehypePlugins={rehypePlugins}
      remarkPlugins={[headingId, remarkGfm, remarkMath]}
    >
      {frontMatter.body}
    </ReactMarkdown>
  );
}
