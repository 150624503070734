import React from "react";
import styled from "styled-components";
import { ActiveLink } from "./ActiveLink";
import { Link, Spacer } from "./Components";

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SectionContainer = styled.div`
  border: 1px solid #4eace8;
  border-radius: 0px 0px 0px 32px;
  padding: 20px;
  padding-top: 0;
  border-top: none;
  border-right: none;
`;

/**
 * Links to the `about` and `blog` pages. When you are on one of those two pages, the link to the
 * page you are on becomes just text, not a link.
 */
export function TitleBarLinks() {
  const currentPage = window.location.pathname;
  const isOnAboutPage = /^\/about$/.test(currentPage);
  const isOnBlogPage = /^\/blog$/.test(currentPage);
  const isOnArchivePage = /^\/archive$/.test(currentPage);
  const isOnWrappedPage = /^\/wrapped-2022$/.test(currentPage);

  return (
    <LinksContainer>
      {isOnAboutPage ? (
        <ActiveLink text="about" />
      ) : (
        <Link href="/about">about</Link>
      )}
      <Spacer width={1.5} />
      {isOnBlogPage ? (
        <ActiveLink text="blog" />
      ) : (
        <Link href="/blog">blog</Link>
      )}
      <Spacer width={1.5} />
      <Link href="http://eepurl.com/hLH6UP">subscribe</Link>
      <Spacer width={1.5} />
    </LinksContainer>
  );
}
