import React from "react";
import styled from "styled-components";
import { AnimatedLogo } from "../pages/archive/AnimatedLogo";
import { Center, Spacer, TitleBar } from "./Components";
import { Monkey, MonkeyContainer } from "./Monkey";
import { TitleBarLinks } from "./TitleBarLinks";

/**
 * All pages other than the landing page are wrapped in this component.
 */
export function SubPage({
  children,
  hideTopBar,
}: {
  children?: React.ReactNode;
  hideTopBar?: boolean;
}) {
  return (
    <>
      {!hideTopBar && (
        <>
          <TitleBar>
            <a href="/">
              <AnimatedLogo depth={1} />
            </a>
            <TitleBarLinks />
          </TitleBar>
          <Spacer height={2} />
        </>
      )}
      <Center style={{ textAlign: "unset" }}>
        <PageContent>{children}</PageContent>
      </Center>
      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </>
  );
}

const PageContent = styled.div`
  width: 600px;
  max-width: 100%;
  box-sizing: border-box;

  img {
    max-width: 100%;
  }

  @media only screen and (max-device-width: 1000px) {
    width: unset;
    padding-left: 48px;
    padding-right: 48px;
  }
`;
