import { color } from '../color/index.mjs';
import { number } from '../numbers/index.mjs';
import { isString, floatRegex, colorRegex, sanitize } from '../utils.mjs';

const colorToken = "${c}";
const numberToken = "${n}";
function test(v) {
    var _a, _b;
    return (isNaN(v) &&
        isString(v) &&
        (((_a = v.match(floatRegex)) === null || _a === void 0 ? void 0 : _a.length) || 0) +
            (((_b = v.match(colorRegex)) === null || _b === void 0 ? void 0 : _b.length) || 0) >
            0);
}
function analyseComplexValue(v) {
    if (typeof v === "number")
        v = `${v}`;
    const values = [];
    let numColors = 0;
    let numNumbers = 0;
    const colors = v.match(colorRegex);
    if (colors) {
        numColors = colors.length;
        // Strip colors from input so they're not picked up by number regex.
        // There's a better way to combine these regex searches, but its beyond my regex skills
        v = v.replace(colorRegex, colorToken);
        values.push(...colors.map(color.parse));
    }
    const numbers = v.match(floatRegex);
    if (numbers) {
        numNumbers = numbers.length;
        v = v.replace(floatRegex, numberToken);
        values.push(...numbers.map(number.parse));
    }
    return { values, numColors, numNumbers, tokenised: v };
}
function parse(v) {
    return analyseComplexValue(v).values;
}
function createTransformer(source) {
    const { values, numColors, tokenised } = analyseComplexValue(source);
    const numValues = values.length;
    return (v) => {
        let output = tokenised;
        for (let i = 0; i < numValues; i++) {
            output = output.replace(i < numColors ? colorToken : numberToken, i < numColors
                ? color.transform(v[i])
                : sanitize(v[i]));
        }
        return output;
    };
}
const convertNumbersToZero = (v) => typeof v === "number" ? 0 : v;
function getAnimatableNone(v) {
    const parsed = parse(v);
    const transformer = createTransformer(v);
    return transformer(parsed.map(convertNumbersToZero));
}
const complex = { test, parse, createTransformer, getAnimatableNone };

export { analyseComplexValue, complex };
