import React from "react";
import styled from "styled-components";
import { Center, Link, Spacer } from "../../components/Components";
import { SubPage } from "../../components/SubPage";

export function AboutPage() {
  return (
    <SubPage>
      0xPARC Foundation accelerates the development and deployment of{" "}
      <Link href="https://0xparc.org/blog/programmable-cryptography-1">
        programmable cryptography
      </Link>
      . We operate, fund, and/or support teams working across the “full stack,"
      from research to production.
      <Spacer height={1} />
      We believe that technologies like zero-knowledge cryptography,
      programmable blockchains, multi-party computation, homomorphic encryption,
      and program obfuscation will enable us to build “civilization-scale”
      digital systems that are much more robust than the digital systems of
      today.
      <Spacer height={1} />
      0xPARC is entirely funded by donations and grants from organizations and
      individuals, including:
      <Spacer height={4} />
      <Sponsors />
      <Spacer height={4} />
      <Center>
        <span>
          For any inquiries, email{" "}
          <Link href="mailto:hello@0xPARC.org">hello@0xPARC.org</Link>
        </span>
      </Center>
      <Spacer height={8} />
    </SubPage>
  );
}

/**
 * A nice grid view of all our sponsors, which turns into a single-column-wide list on small
 * devices.
 */
function Sponsors() {
  return (
    <SponsorsContainer>
      <SponsorImgContainer>
        <a href="https://ethereum.org/en/foundation/">
          <img
            src={require("../../../public/sponsors/ethereum_foundation_sponsor.png")}
          />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        <a href="https://blog.zkga.me/">
          <img src={require("../../../public/sponsors/df_sponsor.png")} />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        <a href="https://gitcoin.co/">
          <img src={require("../../../public/sponsors/gitcoin_sponsor.png")} />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        <a href="https://www.gather.town/">
          <img
            src={require("../../../public/sponsors/gather_sponsor.png")}
            width="100"
          />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        <a href="https://www.xdaichain.com/">
          <img src={require("../../../public/sponsors/gnosis_sponsor.png")} />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        <a href="https://ethglobal.com/">
          <img
            src={require("../../../public/sponsors/eth_global_sponsor.png")}
          />
        </a>
      </SponsorImgContainer>

      <SponsorImgContainer>
        {/* empty to center align the dark forest sponsor */}
      </SponsorImgContainer>

      <SponsorImgContainer>
        <span style={{ fontSize: "80%" }}>
          Anonymous #1 <br />
          Anonymous #2
        </span>
      </SponsorImgContainer>
    </SponsorsContainer>
  );
}

export const SponsorImgContainer = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // left column
  &:nth-child(3n + 1) {
    justify-content: flex-start;
  }

  // center column
  &:nth-child(3n + 2) {
    justify-content: center;
  }

  // right column
  &:nth-child(3n) {
    justify-content: flex-end;
  }

  img {
    max-height: 50px;
  }

  @media only screen and (max-device-width: 1000px) {
    width: 100%;

    // left column
    &:nth-child(3n + 1) {
      justify-content: center;
    }

    // center column
    &:nth-child(3n + 2) {
      justify-content: center;
    }

    // right column
    &:nth-child(3n) {
      justify-content: center;
    }

    img {
      max-height: 100px;
      margin-bottom: 32px;
    }
  }
`;

export const SponsorsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 25px;

  @media only screen and (max-device-width: 1000px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
`;
