import React from "react";
import { BlogPostLink } from "../../components/BlogpostLink";
import {
  Center,
  LogoWithText,
  Spacer,
  VerySmall,
} from "../../components/Components";
import { Monkey, MonkeyContainer } from "../../components/Monkey";
import { TitleBarLinks } from "../../components/TitleBarLinks";
import { posts } from "../blog/BlogPosts";

/**
 * Landing page of 0xPARC website, contains links to other pages.
 */
export function LandingPage() {
  return (
    <Center>
      <Spacer height={15} />
      <LogoWithText />
      <Spacer height={2} />

      <div style={{ textAlign: "center" }}>
        Supporting the next generation of cryptography-enabled applications.
      </div>

      <Spacer height={1} />
      <TitleBarLinks />
      <Spacer height={4} />
      <LatestBlogPost />
      <Spacer height={2} />
      <MonkeyContainer>
        <Monkey />
      </MonkeyContainer>
    </Center>
  );
}

/**
 * Link to the most recent blog post.
 */
function LatestBlogPost() {
  const latest = posts.filter((p) => !p.attributes.hidden)[0];

  if (!latest) {
    return null;
  }

  return (
    <Center>
      <VerySmall>NEW!</VerySmall>
      <br />
      <BlogPostLink post={latest} />
    </Center>
  );
}
